import { patientName } from 'Data/format.js'
import { useDataChange } from 'Simple/Data.js'

export function getTitle(person, appointment_type, hipaa) {
  return `${patientName({
    ...person,
    last_name: !hipaa && person.last_name,
  })}, ${appointment_type.display_name}`
}

export function useOnClick(props) {
  let change = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
    path: 'selected.showDialog',
  })

  return function onClick() {
    change(true)
  }
}
