import { useEffect, useRef } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'overlay',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let location_id_ref = useRef(location_id)

  // close appointment overlay on location change
  useEffect(() => {
    if (location_id !== location_id_ref.current) {
      location_id_ref.current = location_id
      // close overlay
      submit()
    }
  }, [location_id])

  return <View {...props} />
}
