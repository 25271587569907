import React, { useCallback, useRef, useMemo, useEffect } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import {
  useFloating,
  offset,
  autoUpdate,
  autoPlacement,
} from '@floating-ui/react-dom'

import View from './view.js'

export default function Logic(props) {
  let flow = useFlowValue(props.viewPath)
  let triggerRef = useRef()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let { refs, x, y, elements } = useFloating({
    open: flow === 'Content',
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: 5 }),
      autoPlacement({ allowedPlacements: ['bottom', 'top'] }),
    ],
    whileElementsMounted: autoUpdate,
  })
  refs.setReference(triggerRef.current)

  let close = useCallback(() => {
    setFlowTo(normalizePath(props.viewPath, 'No'))
  }, [setFlowTo, props.viewPath])
  let closeRef = useRef(close)
  closeRef.current = close

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target) ||
      // is child sub menu?
      event.target.dataset?.['viewPath']?.includes(props.viewPath)
    ) {
      return
    }

    close()
  })

  let slots = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'scheduling.slots',
  })

  let slot_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'scheduling.slot_id',
  })

  useEffect(() => {
    closeRef.current()
  }, [slot_id])

  let selected = useMemo(
    () => slots?.find(v => v.id === slot_id),
    [slots, slot_id]
  )

  let selectedText = useMemo(() => {
    return selected
      ? `${selected.start_time_formatted} - ${selected.end_time_formatted} (${selected.chair_name})`
      : undefined
  }, [selected])

  return (
    <View
      {...props}
      triggerRef={triggerRef}
      contentRef={refs.setFloating}
      top={y}
      left={x}
      width={elements.reference?.getBoundingClientRect()?.width}
      selectedText={selectedText}
    />
  )
}
