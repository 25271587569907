import { useDataChange, useDataValue, useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return () => {
    submit({ type: 'reset', preservePatientId: true })
    change(next => {
      next.appointment_id = appointment.id
      next.appointment_type_id = appointment.type.id

      if (typeof appointment.duration === 'number') {
        next.duration = appointment.duration
      }

      if (appointment.note) {
        next.notes.enabled = true
        next.notes.value = appointment.note
      }

      if (appointment.booking) {
        if (typeof appointment.booking.duration === 'number') {
          next.duration = appointment.booking.duration
        }
      }
    })
    // close popup
    setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
  }
}
