import { useDataSubmit, useDataChange } from 'Simple/Data.js'

function useChange(props, patient_option) {
  let submitAppointmentOverlay = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'patient_option',
    viewPath: props.viewPath,
  })

  return () => {
    submitAppointmentOverlay({ type: 'reset' })
    submitTab({ type: 'scheduling/reset' })
    change(patient_option)
  }
}

export function useOnNew(props) {
  return useChange(props, 'new')
}

export function useOnExisting(props) {
  return useChange(props, 'existing')
}
