import React, { useEffect, useRef } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import { useFloating, offset, autoUpdate, hide } from '@floating-ui/react-dom'
import useIsHovered from 'Simple/hooks/useIsHovered.js'

import View from './view.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
  })
  let flow = useFlowValue(props.viewPath)
  let triggerRef = useRef()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let { refs, x, y, elements, middlewareData } = useFloating({
    open: flow === 'Content',
    placement: 'left',
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: 5 }),
      hide({ strategy: 'referenceHidden' }),
    ],
    whileElementsMounted: autoUpdate,
  })
  let floatingRef = useRef(elements.floating)
  floatingRef.current = elements.floating
  let parentHidden = middlewareData.hide?.referenceHidden

  useEffect(() => {
    if (floatingRef.current) {
      Object.assign(floatingRef.current.style, {
        visibility: parentHidden ? 'hidden' : 'visible',
      })
    }
  }, [parentHidden])

  refs.setReference(triggerRef.current)

  useOnClickOutside(refs.floating, event => {
    if (
      event.target === refs.reference.current ||
      refs.reference.current?.contains(event.target)
    ) {
      return
    }

    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      triggerRef={triggerRef}
      contentRef={refs.setFloating}
      top={y}
      left={x}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      // used to identify child of nested floating portal
      data-view-path={props.viewPath}
    />
  )
}
